<template>
    <div class="flex flex-col gap-3 items-center">
        <p class="" v-html="t('addWalletsWarning')"></p>
        <button class="btn" @click="toSettings">{{ t('toSettings') }}</button>
    </div>
</template>
<script setup>
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const router = useRouter()
const { t } = useI18n()

const emit = defineEmits(['closeModal'])
const toSettings = () => {
  router.push({
    path: '/settings',
    hash: '#wallets'
  })
  emit('closeModal')
}
onBeforeMount(() => {
  localStorage.setItem('WalletsNotification', Date.now())
})
</script>
