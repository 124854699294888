export default {
  en: {
    increaseSecurity: 'Increase the security of your account with Google Authenticator!',
    clickButton: 'Click "Enable 2FA"',
    downloadApp: 'Download the Google Authenticator app',
    scanQR: 'Scan the QR code',
    enterYourCodeFromApp: 'Enter the code from the application',
    ready: 'Ready! Your account is protected!',
    enableButton: 'Enable 2FA',
    confirmPassword2FA: 'Please confirm your password before continuing',
    confirmButton: 'Confirm Password',
    secretKey: 'Secret key',
    recoveryKey: 'recovery code',
    codeFromApp: 'Your code from app',
    validateButton: 'validate 2FA',
    successful2fa: 'You have successfully connected the 2FA. Your account is now secure.',
    disable2fa: 'Disable 2FA'
  },
  ru: {
    increaseSecurity: 'Увеличьте безопасность своего аккаунта с Google Authenticator!',
    clickButton: 'Нажмите "Подключить 2FA"',
    downloadApp: 'Скачайте приложение Google Authenticator',
    scanQR: 'Отсканируйте QR-код',
    enterYourCodeFromApp: 'Введите код из приложения',
    ready: 'Готово! Ваш аккаунт защищен!',
    enableButton: 'Подключить 2FA',
    confirmPassword2FA: 'Пожалуйста, подтвердите свой пароль перед продолжением',
    confirmButton: 'Подтвердить пароль',
    secretKey: 'Секретный ключ',
    recoveryKey: 'код восстановления',
    codeFromApp: 'Ваш код из приложения',
    validateButton: 'Подтвердить 2FA',
    successful2fa: 'Вы успешно подключили 2FA. Теперь ваша учетная запись защищена.',
    disable2fa: 'Отключить 2FA'
  },
  es: {
    increaseSecurity: 'Aumenta la seguridad de tu cuenta con Google Authenticator!',
    clickButton: 'Haga clic en "Habilitar 2FA"',
    downloadApp: 'Descargar la aplicación Google Authenticator',
    scanQR: 'Escanear el código QR',
    enterYourCodeFromApp: 'Introduzca el código de la aplicación',
    ready: 'Listo! Su cuenta está protegida!',
    enableButton: 'Habilitar 2FA',
    confirmPassword2FA: 'Confirme su contraseña antes de continuar',
    confirmButton: 'Confirmar contraseña',
    secretKey: 'Clave secreta',
    recoveryKey: 'código de recuperación',
    codeFromApp: 'Su código de aplicación',
    validateButton: 'validar 2FA',
    successful2fa: 'Has conectado correctamente el 2FA. Su cuenta es ahora segura.',
    disable2fa: 'Desactivar 2FA'
  }
}
