export default {
  en: {
    settings: 'Settings',
    '2FASecurity': '2FA Security',
    documents: 'Documents',
    social: 'Social',
    faq: 'FAQ',
    logout: 'Logout'
  },
  ru: {
    settings: 'Настройки',
    '2FASecurity': '2FA Security',
    documents: 'Документы',
    social: 'Партнеры',
    faq: 'FAQ',
    logout: 'Выйти'
  },
  es: {
    settings: 'Ajustes',
    '2FASecurity': '2FA Security',
    documents: 'Documents',
    social: 'Socios',
    faq: 'FAQ',
    logout: 'Cerrar Sesión'
  }
}
