import {
  CashIcon,
  UsersIcon,
  CurrencyDollarIcon
} from '@heroicons/vue/outline';

export default {
  state: {
    tokensDate: [
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
      {
        url: 'https://ethplorer.io/address/0x4906b05d8750552ad03081bff52b26025d402aca',
        time: '16:23:45',
        from: '0xf4bf36c5f02440173909c94e59365c7c4463ac7d',
        to: '0x194de4a84892ca93b1f5ff583551ad27036f82ff',
        sum: '1200'
      },
    ],
    OneCapitalValue: 0,
    GoldCapitalValue: 0,
    OnePeriodInvestment: 0,
    GoldPeriodInvestment: 12,
    months: [
      0.040604, 0.082856, 0.126825, 0.172578, 0.220190, 0.2824320, 0.3345039, 0.3886901, 0.4450765, 0.5037524, 0.5648107, 0.6446318, 0.7114105, 0.7809006, 0.8532123,
      0.9284602, 1.0067634, 1.1091285, 1.1947675, 1.2838839, 1.3766187, 1.4731190, 1.5735376, 1.7048138, 1.8146401, 1.9289258, 2.0478519, 2.1716069, 2.3003869, 2.4687398,
      2.6095845, 2.7561481, 2.9086628, 3.0673702, 3.2325217, 3.4484229, 3.6290467, 3.8170046, 4.0125943, 4.2161257, 4.4279213, 4.7047998, 4.9364376, 5.1774808, 5.4283113,
      5.6893265, 5.9609400, 6.3160179, 6.6130775, 6.9221990, 7.2438720, 7.5786063, 7.9269321, 8.3822954, 8.7632542, 9.1596815, 9.5722053, 10.0014792, 10.4481834, 11.0321558
    ],
    goldMonth: [
      0.431481879964349, 0.473155947269205, 0.516043252345379, 0.560179115620775, 0.605599885790517, 0.652342969752411, 0.71269008083049, 0.762550831916398, 0.813863155897295, 0.866669311740878,
      0.921012788680548, 0.976938342031567, 1.04914037266627, 1.10879604487758, 1.17018844497463, 1.23336813350994, 1.29838714298195, 1.36529902068706, 1.45168481670352, 1.52305957840441,
      1.59651224040192, 1.67210329564259, 1.74989499817598, 1.82995141442495, 1.93330731297515, 2.01870332678274, 2.10658543508918, 2.19702601440928, 2.29009954832169, 2.38588268881094,
      2.5095423904948, 2.6117140685564, 2.71686022324099, 2.82506744910538, 2.93642486169352, 3.05102417092879, 3.19897626689084, 3.32121911327759, 3.44702075889133, 3.57648480940152,
      3.70971788670139, 3.84682971671782, 4.02384633896008, 4.1701032447508, 4.32061805992972, 4.4755147430358, 4.63492086135543, 4.79896769598216, 5.0107584
    ],
    onShow: [
      { name: 10 },
      { name: 25 },
      { name: 50 },
      { name: 100 }
    ],
    selectedPeople: { name: 10 },
    transactions: [
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'pending', id: 1
      },
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'created', id: 2
      },
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'pending', id: 3
      },
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'pending', id: 4
      },
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'pending', id: 5
      },
      {
        type: 'depositTransaction', dollars: '2 345', token: '1 513.569196', data: '2022-03-25 10:30:29', status: 'pending', id: 6
      }
    ]
  },
  getters: {
    // chartOptions(state) {
    //   return state.chartData
    // },
    // getSeries(state) {
    //   return state.series
    // },
    getTransaction(state) {
      return state.tokensDate
    },
    getOneTotal(state) {
      return Math.floor(state.OneCapitalValue * state.months[Number(state.OnePeriodInvestment) - 1])
    },
    getOnePeriod(state) {
      return state.OnePeriodInvestment
    },
    getOneCapital(state) {
      return state.OneCapitalValue
    },
    getGoldPeriod(state) {
      return state.GoldPeriodInvestment
    },
    getGoldCapital(state) {
      return state.GoldCapitalValue
    },
    getGoldTotal(state) {
      return Math.floor(state.GoldCapitalValue * state.goldMonth[Number(state.GoldPeriodInvestment - 12)])
    },
    getOnShow(state) {
      return state.onShow
    },
    getSelectedPeople(state) {
      return state.selectedPeople
    },
    getTransactionsTable(state) {
      return state.transactions
    }
  },
  mutations: {
    OneCapitalInput(state, value) {
      state.OneCapitalValue = value
    },
    GoldCapitalInput(state, value) {
      state.GoldCapitalValue = value
    },
    OnePeriodInput(state, value) {
      state.OnePeriodInvestment = value
      if (Number(state.OnePeriodInvestment) > 60) {
        state.OnePeriodInvestment = '60'
      }
    },
    GoldPeriodInput(state, value) {
      state.GoldPeriodInvestment = value
      if (Number(state.GoldPeriodInvestment) > 60) {
        state.GoldPeriodInvestment = '60'
      }
    },
    udpSelectedPeople(state, selected) {
      state.selectedPeople = selected
    }
  }
}
