<template>
  <div class="text-main">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-max w-full pt-5 pb-4 bg-white dark:bg-backgroundBlack">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 overflow-y-auto scroll">
              <nav @click="navItemSelected" class="navigation flex-1 py-4 space-y-1">
                <div
                  v-for="item in sidebarNavigation"
                  class="navigation__item"
                  :key="item.name"
                  :class="[
                    item.nested ? 'navigation__item--nested' : '',
                    $route.path === '/' + item.href ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'Dexnet' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'Fixglobaltrading' ? '!text-[#d3bb84] hover:!text-white font-bold' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
                  <div v-if="item.nested" 
                    class="navigation__nested w-full flex flex-col"
                  >
                    <div class="navigation__unit hover:bg-main-red w-full flex px-2 py-2 items-center">
                      <component
                      :is="item.icon"
                      :className="['group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="['group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ t(item.name) }}
                    </div>
                    
                    <div
                      :class="[isCurrentLinkNested(item) ? 'active' : '']"
                      class="navigation__links_nested flex-col" >
                      <template v-for="(link, index) in item.innerLinks" :key="index">
                        <router-link
                          :to="link.href"
                          class="font-bold p-1"
                          :class="[
                            $route.path === '/' + link.href ? `bg-main-${link.theme ?? 'red'} text-white` : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                          ]"
                        >
                          {{ t(link.name) }}
                        </router-link>
                      </template>
                    </div>
                  </div>
                  <template v-else>
                    <router-link
                      v-if="!item.global"
                      :to=" '/' + item.href"
                      class="w-full flex px-2 py-2 items-center"
                      v-show="!item.children || children"
                      @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
                    >
                      <component
                        v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy' || item.name !== 'Dexnet' || item.name !== 'Fixglobaltrading'"
                        :is="item.icon"
                        :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                        :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      />
                      {{ t(item.name) }}
                    </router-link>
                    <a
                      v-if="item.name === 'cryptohouse'"
                      href="#"
                      @click.prevent="checkout2Lvl"
                      class="group flex items-center text-sm rounded-md w-full px-2 py-2">
                      <component
                        :is="item.icon"
                        :class="[
                          item.current
                          ? 'text-gray-300'
                          : 'text-gray-500 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                        ]"
                        aria-hidden="true"/>
                      {{ t(item.name) }}
                    </a>
                    <a
                      v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT' || item.name === 'Dexnet' || item.name === 'Fixglobaltrading'"
                      :href="item.href"
                      :target="item.name !== 'Dexnet' ? '_blank' : null"
                      class="w-full flex px-2 py-2 items-center"
                    >
                      <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
                      <component
                        v-else-if="item.name === 'Profix-Academy'"
                        :is="item.icon"
                        :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                        :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      />
                      <component
                        v-else-if="item.name === 'Dexnet'"
                        :is="iconDexnet"
                        :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-21']"
                        :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-21']"
                      />
                      <span v-if="item.name === 'Dexnet'"></span>
                      <span v-else-if="item.name === 'Fixglobaltrading'"><img class="mr-3 flex-shrink-0 h-8 w-30" :src="iconFixglobaltrading"  alt=""></span>
                      <span v-else>{{ item.name }}</span>
                    </a>
                  </template>
                </div>
                <hr class="flex h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700 w-full">
                <template v-if="isAdmin">
                  <span class="text-center text-sm font-light text-gray-500 my-2">ADMIN PANEL</span>
                  <div
                    v-for="item in getAdminNavigation"
                    :key="item.name"
                    :class="[
              $route.path === '/' + item.href ? 'bg-orange-500 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
              'group flex items-center  text-sm font-medium rounded-md',
              ]">
                    <router-link
                      :to=" '/' + item.href"
                      class="w-full flex px-2 py-2 items-center"
                      v-if="item.name !== 'cryptohouse'"
                      v-show="!item.children || children"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6'
                ]"
                        aria-hidden="true"/>
                      {{ t(item.name) }}
                    </router-link>
                  </div>
                </template>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <side-bar></side-bar>
    <div class="md:pl-64 flex flex-col">
      <!-- Header -->
      <div class="sticky top-0 z-20 flex-shrink-0 flex h-20 bg-main-sky dark:bg-backgroundBlack drop-shadow-header dark:drop-shadow-none">
        <button type="button" class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="relative flex-1 px-4 flex justify-between">
          <div class="flex-1 hidden lg:flex">
            <FIXTokenHeader/>
          </div>
          <img :src="logo" alt="logo" class="h-5 w-auto self-center mx-auto lg:hidden 2sm:h-8">
          <div class="ml-4 flex items-center md:ml-6">
              <DarkLightToggle class="hidden sm:flex"/>
              <the-language class="hidden md:block" />
              <user-navigation-skelet v-if="loading"/>
              <user-navigation v-show="!loading"/>
          </div>
        </div>
      </div>
      <!-- END Header -->
      <main class="flex-1">
        <FIXTokenHeader class="flex flex-col 2sm:flex-row lg:hidden px-4 sm:px-6 md:px-8 pt-6 gap-3 justify-between" />
        <my-office-skelet v-if="loading"/>
        <router-view class="py-6" v-show="!loading"></router-view>
      </main>
    </div>
<!--    <ModalComponent :title="t('important')" :isOpen="notificationWallets" @openModal="notificationWallets = true" @closeModal="notificationWallets = false">-->
<!--      <modalAlertWallets @closeModal="notificationWallets = false" />-->
<!--    </ModalComponent>-->
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount, onMounted,
  ref
} from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'
import SideBar from '@/components/SideBar.vue'
import { useI18n } from 'vue-i18n';
import TheLanguage from '@/components/TheLanguage.vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import UserNavigation from '@/components/UserNavigation.vue';
import FIXTokenHeader from '@/components/FIXTokenHeader.vue';
import MyOfficeSkelet from '@/components/skeletons/myOfficeSkelet.vue'
import UserNavigationSkelet from '@/components/skeletons/userNavigationSkelet.vue'
import DarkLightToggle from '@/components/UI/DarkLightToggle.vue'
import GlobalNftIcon from '@/components/icons/GlobalNftIcon.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import modalAlertWallets from './components/UI/modalAlertWallets.vue'

import DexnetIconBlack from './components/icons/DexnetIconBlack.vue'
import DexnetIconWhite from './components/icons/DexnetIconWhite.vue'
import FixglobaltradingWhite from './components/icons/FixglobaltradingWhite.vue'
import FixglobaltradingBlack from './components/icons/FixglobaltradingBlack.vue'

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    SideBar,
    TheLanguage,
    UserNavigation,
    FIXTokenHeader,
    MyOfficeSkelet,
    UserNavigationSkelet,
    DarkLightToggle,
    GlobalNftIcon,
    ModalComponent,
    modalAlertWallets
  },
  setup() {

    const store = useStore()
    const { navigation, userNavigation, getAdminNavigation } = store.getters
    const sidebarOpen = ref(false)
    const loading = ref(false)
    const { t } = useI18n()
    const children = ref(false)
    const notificationWallets = ref(false)
    const router = useRouter()
    const route = useRoute()
    const checkout2Lvl = () => {
      document.querySelectorAll('.checkoutClass').forEach((item) => item.classList.toggle('hidden'))
      children.value = !children.value
    }
    const logo = computed(() => store.state.theme === 'dark' ? require('./assets/dark/logo-dark.png') : require('./assets/light/logo-light.png'))
    const iconDexnet = computed(() => store.state.theme === 'dark' ? DexnetIconWhite : DexnetIconBlack)
    const iconFixglobaltrading = computed(() => {
      return store.state.theme === 'dark' ? require(`@/assets/fixoneglobaltradingWthite.png`) : require(`@/assets/fixoneglobaltradingBlack.png`)
    })
    const isAdmin = computed(() => store.getters.getUserInfo.is_admin === 1)
    const fetchData = () => {
      loading.value = true
      store.dispatch('fetchUserInfo').then(() => {
        store.dispatch('fetchStats').then(() => {
          store.dispatch('getPrice').then(() => {
            loading.value = false
          })
        })
      })
    }
    const isCurrentLinkNested = computed(() => (
      item => {
        let hasCurrentLink = false
        item.innerLinks.forEach(link => {
          if (route.path === '/' + link.href) hasCurrentLink = true
        })
        return hasCurrentLink
      }
    ))
    onBeforeMount(() => {
      if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'en')
      }
      store.commit('getCountries')
      store.commit('changeTheme')

      if (localStorage.getItem('verify')) {
        const link = new URL(localStorage.getItem('verify'))
        router.push(link.pathname + link.search)
        localStorage.removeItem('verify')
      }

    })

    const navItemSelected = e => {
      const navigationItem = e.target.closest('.navigation__unit')
      if (!navigationItem) return 
      const nestedItem = navigationItem.closest('.navigation__item').querySelector('.navigation__links_nested')
      if (!nestedItem) return
      nestedItem.classList.toggle('active')
    }
    
    const sidebarNavigation = computed(() => {
      return store.getters.accessNavigation
    })

    return {
      navItemSelected,
      sidebarOpen,
      userNavigation,
      navigation,
      t,
      children,
      checkout2Lvl,
      isAdmin,
      getAdminNavigation,
      fetchData,
      loading,
      logo,
      notificationWallets,
      iconDexnet,
      iconFixglobaltrading,
      sidebarNavigation,
      isCurrentLinkNested
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.navigation__item{
    border-radius: 0 8px 8px 0;
}

.navigation__unit{
    border-radius: 0 8px 8px 0;
}

.navigation__links_nested{
  a{
    border-radius: 0 6px 6px 0;
  } 
}

</style>
