<template>
  <div class="flex items-start justify-between text-main">
    <nav class="relative z-0 inline-flex rounded-md -space-x-px bg-CustomGray" aria-label="Pagination">
      <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
      <!--{&laquo; Previous} {Next &raquo;} -->
        <a
          href="#"
          class="relative inline-flex items-center px-1 py-1 md:px-2 md:py-2 rounded-l-md bg-CustomGray text-sm sm:text-base font-medium hover:text-[#ff8a00]"
          @click.prevent="pageNav.currentPage > 1 ? $emit('updTransactions', pageNav.currentPage  - 1) : null"
        >
          <span class="" >{{ '<< ' + t('previousArrow') }}</span>
          <!-- <ChevronLeftIcon class="h-5 w-5 text-gray-300" aria-hidden="true" /> -->
        </a>
        <a
          href="#"
          aria-current="page"
          v-for="page in pages"
          :class="[
            page !== pageNav.currentPage ? 'bg-CustomGray hover:text-[#ff8a00] ' : 'z-10 text-[#ff8a00]',
            'relative inline-flex items-center px-2 py-1 md:py-2 text-sm sm:text-base font-medium'
            ]"
          :key="'Navigation' + page"
          @click.prevent="$emit('updTransactions', page)"
        >
          {{ page }}
        </a>
        <a
          href="#"
          class="relative inline-flex items-center px-1 py-1 md:px-2 md:py-2 rounded-r-md bg-CustomGray text-sm sm:text-base font-medium hover:text-[#ff8a00]"
          @click.prevent="pageNav.currentPage !== pageNav.lastPage ? $emit('updTransactions', pageNav.currentPage  + 1) : null"
        >
          <span class="">{{ t('nextArrow') + ' >>' }}</span>
          <!-- <ChevronRightIcon class="h-5 w-5 text-gray-300" aria-hidden="true" /> -->
        </a>
    </nav>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';

// pageNav = {
// currentPage: 1,
// lastPage: 12,
// firstPage: 1
// }
const { t } = useI18n()
const props = defineProps({
  pageNav: Object
})
const pages = computed(() => {
  // const pagesArr = []
  // pagesArr.push(props.pageNav.currentPage)
  // if (props.pageNav.currentPage + 1 <= props.pageNav.lastPage) {
  //   pagesArr.push(props.pageNav.currentPage + 1)
  // }
  // if (props.pageNav.currentPage + 2 <= props.pageNav.lastPage) {
  //   pagesArr.push(props.pageNav.currentPage + 2)
  // }
  // if (props.pageNav.currentPage > 1) {
  //   pagesArr.splice(0, 0, 1)
  //   pagesArr.splice(1, 0, '...')
  // }
  // if (!pagesArr.some((item) => item === props.pageNav.lastPage)) {
  //   pagesArr.splice(pagesArr.length, 0, props.pageNav.lastPage)
  //   pagesArr.splice(pagesArr.length - 1, 0, '...')
  // }
  // return pagesArr
  const res = [];
  const minPaginationElems = 3 + 1 * 2;
  let rangeStart = props.pageNav.lastPage <= minPaginationElems ? 1 : props.pageNav.currentPage - 1;
  let rangeEnd = props.pageNav.lastPage <= minPaginationElems ? props.pageNav.lastPage : props.pageNav.currentPage + 1;
  rangeEnd = rangeEnd > props.pageNav.lastPage ? props.pageNav.lastPage : rangeEnd;
  rangeStart = rangeStart < 1 ? 1 : rangeStart;
  if (props.pageNav.lastPage > minPaginationElems) {
    const isStartBoundaryReached = rangeStart - 1 < 3;
    const isEndBoundaryReached = props.pageNav.lastPage - rangeEnd < 3;
    if (isStartBoundaryReached) {
      rangeEnd = minPaginationElems - 2;
      for (let i = 1; i < rangeStart; i++) {
        res.push(i);
      }
    } else {
      res.push(1);
      res.push('...');
    }
    if (isEndBoundaryReached) {
      rangeStart = props.pageNav.lastPage - (minPaginationElems - 3);
      for (let i = rangeStart; i <= props.pageNav.lastPage; i++) {
        res.push(i);
      }
    } else {
      for (let i = rangeStart; i <= rangeEnd; i++) {
        res.push(i);
      }
      res.push('...');
      res.push(props.pageNav.lastPage);
    }
  } else {
    for (let i = rangeStart; i <= rangeEnd; i++) {
      res.push(i);
    }
  }
  return res
})
</script>
