<template>
  <div :class="['mt-4', btnClass]">
    <button
      type="button"
      @click="$emit('openModal')"
      :class="[
        theme === 'default' ? 'text-white bg-main-red' : '',
        theme === 'dex' ? 'text-black bg-main-dex' : '',
        'rounded-md p-2 text-center w-full']"
    >
      {{ props.btnTitle }}
    </button>
  </div>
  <TransitionRoot appear :show="props.isOpen" as="template">
    <Dialog as="div" @close="$emit('closeModal')">
      <div class="fixed inset-0 z-30 overflow-y-auto">
        <div class="min-h-screen px-4 text-center text-main">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black opacity-10 dark:opacity-30" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class=" relative inline-block w-full  p-6 my-8 overflow-hidden text-left align-middle  transition-all transform rounded-2xl"
                :class="[
                  bigSize ? 'max-w-2xl' : 'max-w-md',
                  theme === 'default' ? 'card-bg dark:!border-[#ff1e00]' : '',
                  theme === 'dex' ? 'card-bg--dexnet' : ''
                ]"
              
                >
                <button
                  type="button"
                  class="absolute top-3 right-3"
                  @click="closeModal"
                >
                  <XIcon class="w-6 h-6 dark:text-gray-400"/>
                </button>
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6"
              >
                {{ props.title }}
              </DialogTitle>
              <div class="mt-2">
                <slot></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'
import { XCircleIcon, XIcon } from '@heroicons/vue/solid';

const props = defineProps({
  bigSize: {
    type: Boolean,
    default: () => false
  },
  theme: {
    type: String,
    required: false,
    default: () => 'default'
  },
  title: String,
  btnTitle: String,
  isOpen: Boolean,
  btnClass: String || Array,
})
const emit = defineEmits(['closeModal', 'openModal'])

function closeModal() {
  emit('closeModal')
}
function openModal() {
  emit('openModal')
}
</script>
