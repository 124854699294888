import {
  ChartPieIcon,
  CogIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
  ShieldExclamationIcon,
  UsersIcon,
  CollectionIcon,
  DesktopComputerIcon,
  DatabaseIcon,
  UserAddIcon,
  CakeIcon,
} from '@heroicons/vue/outline';
import HomeIcon from '@/components/icons/HomeIcon.vue'
import DepositIcon from '@/components/icons/DepositIcon.vue'
import FixTokenAccountIcon from '@/components/icons/FixTokenAccountIcon.vue'
import PartnershipAccountIcon from '@/components/icons/PartneshipAccountIcon.vue'
import TransactionsIcon from '@/components/icons/TransactionsIcon.vue'
import WithdrawIcons from '@/components/icons/WithdrawIcons.vue'
import PartnersIcon from '@/components/icons/PartnersIcon.vue'
import GlobalPoolIcon from '@/components/icons/GlobalPoolIcon.vue'
import MarketingToolsIcon from '@/components/icons/MarketingToolsIcon.vue'
import PersonalManagerIcon from '@/components/icons/PersonalManagerIcon.vue'
import PartnerAgreementsIcon from '@/components/icons/PartnerAgreementsIcon.vue'
import FaqIcon from '@/components/icons/FaqIcon.vue'
import ProfixHomeIcon from '@/components/icons/ProfixHomeIcon.vue'
import FixAcademyIcon from '@/components/icons/FixAcademyIcon.vue'
import GlobalNftIcon from '@/components/icons/GlobalNftIcon.vue'
import BroadcastIcon from '@/components/icons/BroadcastIcon.vue'
import { shallowRef } from 'vue';

const fixAcademyIcon = shallowRef(FixAcademyIcon)
const homeIcon = shallowRef(HomeIcon)
const depositIcon = shallowRef(DepositIcon)
const fixTokenAccountIcon = shallowRef(FixTokenAccountIcon)
const partnershipAccountIcon = shallowRef(PartnershipAccountIcon)
const transactionsIcon = shallowRef(TransactionsIcon)
const withdrawIcons = shallowRef(WithdrawIcons)
const partnersIcon = shallowRef(PartnersIcon)
const globalPoolIcon = shallowRef(GlobalPoolIcon)
const faqIcon = shallowRef(FaqIcon)
const profixHomeIcon = shallowRef(ProfixHomeIcon)
const globalNftIcon = shallowRef(GlobalNftIcon)
const broadcastIcon = shallowRef(BroadcastIcon)
const marketingToolsIcon = shallowRef(MarketingToolsIcon)
const personalManagerIcon = shallowRef(PersonalManagerIcon)
const partnerAgreementsIcon = shallowRef(PartnerAgreementsIcon)


export default {
  state: {
    navigation: [
      { name: 'myOffice', href: '', icon: homeIcon },
      { name: 'deposit',
        href: 'deposit',
        icon: depositIcon,
        nested: true,
        innerLinks: [
          {
            name: 'depositProfixone',
            href: 'deposit',
            access: 'profixone'
          },
          {
            name: 'buyDexNode',
            href: 'dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'FIXTokenCheck',
        href: 'FIXTokenCheck',
        icon: fixTokenAccountIcon,
        access: 'profixone'
      },
      { name: 'partnershipAccount',
        href: 'partnershipAccount',
        icon: partnershipAccountIcon,
        nested: true,
        innerLinks: [
          {
            name: 'partnershipAccountProfix',
            href: 'partnershipAccount',
            access: 'profixone'
          },
          {
            name: 'partnershipAccountDexnet',
            href: 'partnershipAccount-dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'transactions', href: 'transactions', icon: transactionsIcon },
      { name: 'withdrawl',
        href: 'withdraw',
        icon: withdrawIcons,
        nested: true,
        innerLinks: [
          {
            name: 'withdrawlProfixone',
            href: 'withdraw',
            access: 'profixone'
          },
          {
            name: 'withdrawDexnet',
            href: 'withdraw-dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'partners', href: 'partners', icon: partnersIcon },
      { 
        name: 'globalpool',
        href: 'globalpool',
        icon: globalPoolIcon,
        access: 'profixone'
      },
      {
        name: 'Fixglobaltrading',
        href: 'https://fixoneglobal.com/global/',
        icon: null,
        global: true
      },
      // {
      //   name: 'Profix-Academy',
      //   href: 'https://profix.academy/',
      //   icon: FixAcademyIcon,
      //   global: true
      // },
      // {
      //   name: 'cryptohouse',
      //   href: '#',
      //   icon: ProfixHomeIcon,
      //   global: true
      // },
      {
        name: 'northencyprus',
        href: 'northencyprus',
        icon: profixHomeIcon,
        children: true
      },
      {
        name: 'spain',
        href: 'spain',
        icon: profixHomeIcon,
        children: true
      },
      {
        name: 'Dexnet',
        href: '/dexnet',
        icon: null,
        global: true
      },
      {
        name: 'FIX Global NFT',
        href: 'https://fixglobal.pro/',
        icon: globalNftIcon,
        global: true
      },

      { name: 'broadcast', href: 'broadcasts', icon: broadcastIcon },
      { name: 'marketing', href: 'marketing', icon: marketingToolsIcon },
      { name: 'manager', href: 'personalManager', icon: personalManagerIcon },
      { name: 'partnerAgreements', href: 'partnerAgreements', icon: partnerAgreementsIcon },
      { name: 'faq', href: 'faq', icon: faqIcon },
    ],
    navigationDexnet: [
      { name: 'myOffice', href: '', icon: homeIcon },
      { name: 'deposit',
        href: 'deposit',
        icon: depositIcon,
        nested: true,
        innerLinks: [
          {
            name: 'buyDexNode',
            href: 'dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'partnershipAccount',
        href: 'partnershipAccount',
        icon: partnershipAccountIcon,
        nested: true,
        innerLinks: [
          {
            name: 'partnershipAccountDexnet',
            href: 'partnershipAccount-dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'transactions', href: 'transactions', icon: transactionsIcon },
      { name: 'withdrawl',
        href: 'withdraw',
        icon: withdrawIcons,
        nested: true,
        innerLinks: [
          {
            name: 'withdrawDexnet',
            href: 'withdraw-dexnet',
            theme: 'dex'
          }
        ]
      },
      { name: 'partners', href: 'partners', icon: partnersIcon },
      // { 
      //   name: 'globalpool',
      //   href: 'globalpool',
      //   icon: globalPoolIcon,
      //   access: 'profixone'
      // },
      {
        name: 'Fixglobaltrading',
        href: 'https://fixoneglobal.com/global/',
        icon: null,
        global: true
      },
      // {
      //   name: 'Profix-Academy',
      //   href: 'https://profix.academy/',
      //   icon: FixAcademyIcon,
      //   global: true
      // },
      // {
      //   name: 'cryptohouse',
      //   href: '#',
      //   icon: ProfixHomeIcon,
      //   global: true
      // },
      {
        name: 'northencyprus',
        href: 'northencyprus',
        icon: profixHomeIcon,
        children: true
      },
      {
        name: 'spain',
        href: 'spain',
        icon: profixHomeIcon,
        children: true
      },
      {
        name: 'Dexnet',
        href: '/dexnet',
        icon: null,
        global: true
      },
      {
        name: 'FIX Global NFT',
        href: 'https://fixglobal.pro/',
        icon: globalNftIcon,
        global: true
      },

      { name: 'marketing', href: 'marketing', icon: marketingToolsIcon },
      { name: 'manager', href: 'personalManager', icon: personalManagerIcon },
      // { name: 'partnerAgreements', href: 'partnerAgreements', icon: partnerAgreementsIcon },
      { name: 'faq', href: 'faq', icon: faqIcon },
    ],
    userNavigation: [
      { name: 'settings', href: '/settings', icon: CogIcon },
      { name: '2FASecurity', href: '/two-factor', icon: ShieldExclamationIcon },
      { name: 'documents', href: '/documents', icon: DocumentTextIcon },
      // { name: 'partners', href: '/partners', icon: UsersIcon },
      { name: 'faq', href: '/faq', icon: QuestionMarkCircleIcon },
      { name: 'logout', href: '#', icon: LogoutIcon },
    ],
    navigationAdmin: [
      { name: 'adminDashboard', href: 'admin', icon: DesktopComputerIcon },
      { name: 'manageUsers', href: 'admin/users', icon: CogIcon },
      { name: 'deposits', href: 'admin/deposits', icon: CurrencyDollarIcon },
      { name: 'withdrawLink', href: 'admin/withdraw', icon: CollectionIcon },
      { name: 'transactions', href: 'admin/transactions', icon: DatabaseIcon },
      { name: 'globalpool', href: 'admin/global-pool', icon: ChartPieIcon },
      { name: 'promotion', href: 'admin/promotion', icon: ChartPieIcon },
      { name: 'verifications', href: 'admin/verification', icon: UserAddIcon },
      { name: 'Birthdays', href: 'admin/birthdays', icon: CakeIcon },
    ]
  },
  getters: {
    navigation(state) {
      return state.navigation
    },
    userNavigation(state) {
      return state.userNavigation
    },
    getAdminNavigation(state) {
      return state.navigationAdmin
    },
    accessNavigation(state, getters, rootState, rootGetters) {
      const inActiveUser = !rootGetters.getIsActiveStatus
      const positiveBalance = rootGetters.getFixOneBalance || rootGetters.getFixGoldBalance
      let nav = getters.navigation.filter(elem => elem.name !== 'broadcast')
      return inActiveUser ? state.navigationDexnet : positiveBalance ? state.navigation : nav
    }
  }
}
