export default {
  en: {
    maldives2024: `
    <div class="promoMay2024__rules">
        <span class="promoMay2024__cap_rule"> PROMO BRONZE</span>
        • 25.000$ personal investment <br>
        • 75.000$ With 3 investors <br>
        BONUS: <br>
        - Paid for hotel accommodation 5* <br>  All Inclusive - 6 nights in the Maldives<br>
        - A compliment from the company (+ 1 night as a gift) <br>
      <br>
      <span class="promoMay2024__cap_rule"> PROMO SILVER </span>
        • 50.000$  personal investment<br>
        • 150.000$ With 3 investors <br>
        BONUS: <br>
        - Paid for hotel accommodation 5* <br>
        All Inclusive - 6 nights in the Maldives<br>
        - Access to all events Happy Birthday 2024<br>
        - Personal photo shoot in the Maldives <br>
        -  A compliment from the company (+ 1 night as a gift) 
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO GOLD</span>
      • 100.000$ personal investment <br>
      • 300.000$ With 3 investors <br>
      BONUS: <br>
        - Paid for hotel accommodation 5* <br>
        All Inclusive - 6 nights in the Maldives <br>
        - Access to all events Happy Birthday 2024  <br>
        - Personal photo shoot in the Maldives<br>
        - Private dinner with the Founder of the company
        - A compliment from the company    
        (+ 1 night as a gift)  
        - 1 NFT FIX Gold
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO DIAMOND</span>
      • 150.000$ personal investment <br>
      • 450.000$ With 3 investors <br>
      BONUS: <br>
        - Flight<br>
        - Paid for hotel accommodation 5* <br>
        All Inclusive - 6 nights in the Maldives  <br>
        - Access to all events Happy Birthday 2024 <br>
        - Personal photo shoot in the Maldives <br>
        - Private dinner with the Founder of the company <br>
        - A compliment from the company (+ 1 night as a gift) <br>
        - 2 NFT FIX Gold <br>
        <br>
      <p class='promoMay2024__more'>
      Number of seats: 50<br>
      Event: May 2024<br>
      The minimum deposit period is 6 months after the completion of the promo.
      </p>
    </div>
    `,
    rules: `
    <div class="globalTour__rules">
        <span class="globalTour__cap_rule"> 1. Personal Investment</span>
        • $25,000 or 3 investors at $35,000 each <br>
        - 50% payment of cruise expenses <br>
        - A gift from the company <br>
      <br>
      <span class="globalTour__cap_rule"> 2. Personal Investment </span>
      • $50,000 or 3 investors at $50,000 each<br>
        - 75% payment of cruise expenses<br>
        - Personal audience with the founders of the company<br>
        - A gift from the company <br>
        <br>
      <span class="globalTour__cap_rule"> 3. Personal Investment</span>
      • $75,000 or 3 investors at $75,000 each <br>
        - 100% payment of cruise expenses <br>
        - Personal audience with the founders of the company + VIP dinner <br>
        - A gift from the company <br>
        <br>
      <span class="globalTour__cap_rule"> 4. Personal Investment</span>
      • $100,000 or 3 investors at $100,000 each <br>
        - VIP ticket for cruise + flight <br>
        - Personal audience with the founders of the company + VIP dinner <br>
        - NFT FIX Gold <br>
        - A gift from the company <br>
        <br>
      <span class="globalTour__cap_rule">5. Personal Investment</span>
      • $150,000 or 3 investors at $150,000 each<br>
        - VIP ticket for cruise + flight<br>
        - Personal audience with the founders of the company + VIP dinner<br>
        - NFT FIX Gold + Physical Coin<br>
        - A gift from the company<br>
      <p class="globalTour__more" > <a target="_blank"  href="/documents/cruise.pdf"> More </a> </p>
    </div>`,
  },
  ru: {
    maldives2024: `
    <div class="promoMay2024__rules">
        <span class="promoMay2024__cap_rule"> PROMO BRONZE</span>
        • 25.000$ личная инвестиция <br>
        • 75.000$ С 3 инвесторами  <br>
        BONUS: <br>
        - Оплачено Проживание в отеле 5*<br> All Inclusive - 6 ночей на Мальдивах<br>
        - Комплимент от компании (+ 1 ночь в подарок) <br>
      <br>
      <span class="promoMay2024__cap_rule"> PROMO SILVER </span>
        • 50.000$  личная инвестиция<br>
        • 150.000$ С 3 инвесторами 
        BONUS: <br>
        - Оплачено проживание в отеле 5* <br>
        All Inclusive - 6 ночей на Мальдивах<br>
        - Доступ на все мероприятия Happy Birthday 2024<br>
        - Личная фотосессия на Мальдивах  <br>
        -  Комплимент от компании (+ 1 ночь в подарок)  
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO GOLD</span>
      • 100.000$ личная инвестиция <br>
      • 300.000$ С 3 инвесторами <br>
      BONUS: <br>
        - Оплачено проживание в отеле 5* <br>
        All Inclusive- 6 ночей на Мальдивах <br>
        - Доступ на все мероприятия Happy Birthday 2024 <br>
        - Личная фотосессия на Мальдивах<br>
        - Закрытый ужин с Учредителем компании 
        - Комплимент от компании (+ 1 ночь в подарок)
        - 1 NFT FIX Gold
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO DIAMOND</span>
      • 150.000$ личная инвестиция <br>
      • 450.000$ С 3 инвесторами<br>
      BONUS: <br>
        - Перелет <br>
        - Оплачено проживание в отеле 5* <br>
        All Inclusive- 6 ночей на Мальдивах   <br>
        - Доступ на все мероприятия Happy Birthday 2024 <br>
        - Личная фотосессия на Мальдивах  <br>
        - Закрытый ужин с Учредителем компании <br>
        - Комплимент от компании  (+ 1 ночь в подарок) <br>
        - 2 NFT FIX Gold <br>
        <br>
      <p class='promoMay2024__more'>
      Количество место: 50<br>
      Мероприятие: Май 2024<br>
      Минимальный срок депозита 6 месяцев после завершения промо.
      </p>
    </div>
    `,
    rules: `
    <div class="globalTour__rules">
        <span class="globalTour__cap_rule"> 1. Личная инвестиция </span>
        • 25.000$ или 3 инвестора по 35.000$  <br>
        - Оплата 50% расходов круиза  <br>
        - Презент от компании  <br>
      <br>
      <span class="globalTour__cap_rule"> 2. Личная инвестиция </span>
      • 50.000$ или 3 инвестора по 50.000$ <br>
        - 75% оплата круиза <br>
        - Личная аудиенция с Учредителями компании<br>
        - Презент от компании  <br>
        <br>
      <span class="globalTour__cap_rule"> 3.  Личная инвестиция </span>
      • 75.000$ или 3 инвестора по 75.000$ <br>
        - 100% оплата круиза  <br>
        - Личная аудиенция с Учредителями компании + VIP Ужин  <br>
        - Презент от компании  <br>
        <br>
      <span class="globalTour__cap_rule"> 4. Личная инвестиция </span>
      • 100.000$ или 3 инвестора по 100.000$ <br>
        - VIP ticket на круиз + перелет <br>
        - Личная аудиенция с Учредителями компании + VIP ужин  <br>
        - NFT FIX Gold  <br>
        - Презент от компании <br>
        <br>
      <span class="globalTour__cap_rule">5. Личная инвестиция</span>
      •  150.000$ или 3 инвестора по 150.000$<br>
        - VIP ticket на круиз + перелет<br>
        - Личная аудиенция с Учредителями компании + VIP ужин<br> 
        - NFT FIX Gold + Физическая монетка<br>
        - Презент от компании<br>
      <p class="globalTour__more" > <a target="_blank"  href="/documents/cruiseru.pdf"> Подробнее </a> </p>
    </div>`,
  },
  es: {
    rules: `
    <div class="globalTour__rules">
        <span class="globalTour__cap_rule"> 1. Inversión personal</span>
        • $25,000 o 3 inversores a $35,000 cada uno <br>
        - Pago del 50% de los gastos del crucero <br>
        - Un regalo de la empresa <br>
      <br>
      <span class="globalTour__cap_rule"> 2. Inversión personal </span>
      • $50,000 o 3 inversores a $50,000 cada uno<br>
        - Pago del 75% de los gastos del crucero<br>
        - Audiencia personal con los fundadores de la empresa<br>
        - Un regalo de la empresa <br>
        <br>
      <span class="globalTour__cap_rule"> 3. Inversión personal</span>
      • $75,000 o 3 inversores a $75,000 cada uno <br>
        - Pago del 100% de los gastos del crucero <br>
        - Audiencia personal con los fundadores de la empresa + Cena VIP <br>
        - Un regalo de la empresa <br>
        <br>
      <span class="globalTour__cap_rule"> 4. Inversión personal</span>
      • $100,000 o 3 inversores a $100,000 cada uno <br>
        - Boleto VIP para el crucero + vuelo <br>
        - Audiencia personal con los fundadores de la empresa + Cena VIP <br>
        - NFT FIX Gold <br>
        - Un regalo de la empresa <br>
        <br>
      <span class="globalTour__cap_rule">5. Inversión personal</span>
      • $150,000 o 3 inversores a $150,000 cada uno<br>
        - Boleto VIP para el crucero + vuelo<br>
        - Audiencia personal con los fundadores de la empresa + Cena VIP<br>
        - NFT FIX Gold + Moneda física<br>
        - Un regalo de la empresa<br>
      <p class="globalTour__more" > <a target="_blank"  href="/documents/cruise.pdf"> More </a> </p>
    </div>`,
    maldives2024: `
    <div class="promoMay2024__rules">
        <span class="promoMay2024__cap_rule"> PROMO BRONZE</span>
        • 25.000$ personal investment <br>
        • 75.000$ With 3 investors <br>
        BONUS: <br>
        - Paid for hotel accommodation 5* <br>  All Inclusive - 6 nights in the Maldives<br>
        - A compliment from the company (+ 1 night as a gift) <br>
      <br>
      <span class="promoMay2024__cap_rule"> PROMO SILVER </span>
        • 50.000$  personal investment<br>
        • 150.000$ With 3 investors 
        BONUS: <br>
        - Paid for hotel accommodation 5*
        All Inclusive - 6 nights in the Maldives<br>
        - Access to all events Happy Birthday 2024<br>
        - Personal photo shoot in the Maldives <br>
        -  A compliment from the company (+ 1 night as a gift) 
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO GOLD</span>
      • 100.000$ personal investment <br>
      • 300.000$ With 3 investors <br>
        - Paid for hotel accommodation 5* <br>
        All Inclusive - 6 nights in the Maldives <br>
        - Access to all events Happy Birthday 2024  <br>
        - Personal photo shoot in the Maldives<br>
        - Private dinner with the Founder of the company
        - A compliment from the company    
        (+ 1 night as a gift)  
        - 1 NFT FIX Gold
        <br>
        <br>
      <span class="promoMay2024__cap_rule"> PROMO DIAMOND</span>
      • 150.000$ personal investment <br>
      • 450.000$ With 3 investors <br>
      BONUS: <br>
        - Flight<br>
        - Paid for hotel accommodation 5* <br>
        All Inclusive - 6 nights in the Maldives  <br>
        - Access to all events Happy Birthday 2024 <br>
        - Personal photo shoot in the Maldives <br>
        - Private dinner with the Founder of the company <br>
        - A compliment from the company (+ 1 night as a gift) <br>
        - 2 NFT FIX Gold <br>
        <br>
      <p class='promoMay2024__more'>
      Number of seats: 50<br>
      Event: May 2024<br>
      The minimum deposit period is 6 months after the completion of the promo.
      </p>
    </div>
    `,
  }
}
