<template>
  <div class="w-full px-4 sm:px-6 md:px-8">
    <div class="card text-main">
      <div class="card-bg" v-if="false">
        <h4 class="text-lg">{{ t('stepOneConfirm') }}</h4>
        <div class="mt-4 ml-1">
          <h5>{{ t('howToDeposit') }} <br> {{ t('below') }}</h5>
          <h6 class="mt-2">{{ t('steOneDeposit') }}</h6>
          <h6>{{ t('stepTwoDeposit') }}</h6>
          <div class="mt-2 flex flex-col" v-for="item in stateUsdtAddress" :key="item.title">
            <small class="font-light">{{ item.title }}</small>
            <strong class="text-orange-600 break-words">{{ item.address }}</strong>
          </div>
          <h6 class="mt-2">{{ t('stepThreeDeposit') }}</h6>
          <h6 class="mt-2">
            <small class="font-light">{{ t('blockchainConfirmations') }}</small>
            <br>
            {{ t('totalYouNeed') }}
          </h6>
            <label
              class="border !border-[#ea580c] input flex justify-between items-center pr-3 dark:bg-[#171717]"
            >
              <input
                type="text"
                class="w-full border-transparent focus:border-transparent focus:ring-0 rounded-xl bg-white dark:bg-[#171717] text-xxs sm:text-lg"
                v-model="total"
                disabled
              >
            </label>
        </div>
      </div>
      <div class="card-bg" v-if="false">
        <h4 class="text-lg">{{ t('stepTwoConfirm') }}</h4>
        <my-input :title="t('hashTransaction')" class="mt-4" v-model="hash"/>
        <label for="uploadScreenshot" class="flex justify-between gap-1 border rounded-md cursor-pointer mt-4">
          <span class="p-1 truncate">{{ screenshot }}</span>
          <span class="border-l p-1 rounded-md text-sm sm:text-base w-1/2 sm:w-auto">{{ t('screenshotTransaction') }}</span>
          <input type="file" id="uploadScreenshot" name="uploadScreenshot" class="hidden" @change="update">
        </label>
       <button
          class="btn px-6 mt-4"
          @click="confirmPayment"
        >
          {{ !isLoad ? t('deposit') : '' }}
          <loader v-show="isLoad"/>
        </button>
      </div>
    </div>
    <div class="card-bg mt-4">
      <transactions-table :table-titles="getTransactionsTableApiKey.slice(0, -1)" :transactions="transaction"/>
    </div>
    <notification :showNotification="showNotification" @close="showNotification = false">
      <p class="text-sm font-medium text-gray-900">{{ t('success') }}</p>
      <p class="mt-1 text-sm text-gray-500">{{ t('successWait') }}</p>
    </notification>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import MyInput from '@/components/UI/myInput.vue'
import TransactionsTable from '@/components/transactionsTable.vue'
import Notification from '@/components/UI/notification.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { onBeforeMount, ref } from 'vue'
import axios from 'axios'
import { url } from '@/main'
import Loader from '@/components/UI/loaderUI.vue'

const hash = ref('')
const screenshot = ref('')
const route = useRoute()
const isLoad = ref(false)
const total = ref('')
const status = ref('')
const transaction = ref([])
const showNotification = ref(false)

const fetchTransaction = () => {
  const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3333' : url
  })
  instance.get(`/api/payments/transaction/${route.params.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then((response) => {
      console.log(response)
      status.value = response.data.status
      total.value = response.data.data.amount_in_usd
      total.value += ' USDT'
      transaction.value = [
        {
          type: response.data.data.type,
          dollars: response.data.data.amount_in_usd,
          apikey: response.data.data.data.promo,
          data: response.data.data.created_at.split('.')[0].split('T').join(' '),
          status: response.data.data.status,
        }
      ]
      if (response.data.data.status === 10) {
        setTimeout(() => {
          fetchTransaction()
        }, 5000)
      }
    }).catch((err) => {
      console.log(err)
      if (err.response.status === 401) {
        localStorage.removeItem('tokenAuth')
        document.location.href = document.location.origin
      }
    })
}
onBeforeMount(() => {
  fetchTransaction()
})
const props = defineProps({
  type: String
})
const { t } = useI18n()
const stateUsdtAddress = [
  { title: 'USDT - ERC20', address: '0xEff9741Af483c2A2b7A82490cC18E616c8dDdCd0' },
  { title: 'USDT - TRC20', address: 'TYg3UgiimRMsBmq5z4UYT9pzD4fT3YUHtY' },
  { title: 'USDT - BEP20', address: '0xEff9741Af483c2A2b7A82490cC18E616c8dDdCd0'}
]
const store = useStore()
const { getTransactionsTableApiKey } = store.getters
const update = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  screenshot.value = idScreenshot.name
}

const confirmPayment = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  const formData = new FormData()

  if (hash.value) formData.append('tx_id', hash.value)

  if (screenshot.value) formData.append('photo', idScreenshot)

  isLoad.value = true

  axios.post(`${url}/api/payments/deposit/${route.params.id}`, formData, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then(() => {
      status.value = 10
      fetchTransaction()
      showNotification.value = true
      isLoad.value = false
    })
    .catch(() => {
      isLoad.value = false
      if (err.response.status === 401) {
        localStorage.removeItem('tokenAuth')
        document.location.href = document.location.origin
      }
    })
}
</script>
