<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton class="max-w-xs flex items-center gap-2 text-sm rounded-full focus:outline-none text-[#758ba4] dark:text-white">
        {{ active.name }}
        <img :src="active.img" alt="country" class="w-4 mr-2.5">
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 bg-white dark:bg-black rounded-md shadow-2xl py-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuItem v-for="item in languages" :key="item.country" v-slot="{ active }">
            <button :class="[active ? 'bg-main-red !text-white' : '', 'flex px-4 py-2 text-sm text-gray-400 dark:text-white w-full items-center']" @click="changeLanguage(item.country)">
              <img :src="item.img" alt="country" class="w-4 mr-2.5">
              {{ item.name }}
            </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from '@headlessui/vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })
    const store = useStore()
    const { languages } = store.getters
    const active = computed(() => store.getters.activeLanguage)
    const changeLanguage = (language) => {
      store.dispatch('CHANGE_LANGUAGE', language)
      locale.value = language
    }

    return {
      languages,
      active,
      changeLanguage
    }
  }
}
</script>

<style>

</style>
