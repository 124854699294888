<template>
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex-1 flex flex-col min-h-0 bg-white dark:bg-backgroundBlack">
      <div class="flex items-center h-16 flex-shrink-0 pl-5">
        <img class="h-8 w-auto" :src="logo" alt="Workflow"/>
      </div>
      <hr class="px-5 self-center w-10/12 h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700">
      <div class="flex-1 flex flex-col overflow-y-auto scroll">
        <nav @click="navItemSelected" class="navigation flex-1 py-4 space-y-1">
          <div
            v-for="item in sidebarNavigation"
            class="navigation__item"
            :key="item.name"
            :class="[
                    item.nested ? 'navigation__item--nested' : '',
                    $route.path === '/' + item.href && !item.nested ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'Dexnet' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'Fixglobaltrading' ? '!text-[#d3bb84] hover:!text-white font-bold' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
            <div v-if="item.nested" 
              class="navigation__nested w-full flex flex-col"
            >
              <div class="navigation__unit hover:bg-main-red w-full flex px-2 py-2 items-center">
                <component
                :is="item.icon"
                :className="['group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                :class="['group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
              />
              {{ t(item.name) }}
              </div>
              
              <div
                :class="[isCurrentLinkNested(item) ? 'active' : '']"
                class="navigation__links_nested flex-col" >
                <template v-for="(link, index) in item.innerLinks" :key="index">
                  <router-link
                    :to="link.href"
                    class="font-bold p-1"
                    :class="[
                      $route.path === '/' + link.href ? `bg-main-${link.theme ?? 'red'} text-white` : 'text-gray-500 dark:text-gray-300 ',
                      link.theme === 'dex' ? 'hover:bg-main-dex hover:text-black dex-link' : 'hover:bg-main-red hover:text-white'
                    ]"
                  >
                    {{ t(link.name) }}
                  </router-link>
                </template>
              </div>
            </div>
            <template v-else>
              <router-link
                v-if="!item.global"
                :to=" '/' + item.href"
                class="w-full flex px-2 py-2 items-center"
                v-show="!item.children || children"
                @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
              >
                <component
                  v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy'  || item.name !== 'Dexnet' || item.name !== 'Fixglobaltrading'"
                  :is="item.icon"
                  :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                  :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                />
                {{ t(item.name) }}
              </router-link>
              <a
                v-if="item.name === 'cryptohouse'"
                href="#"
                @click.prevent="checkout2Lvl"
                class="group flex items-center text-sm rounded-md w-full px-2 py-2">
                <component
                  :is="item.icon"
                  :class="[
                          item.current
                          ? 'text-gray-300'
                          : 'text-gray-500 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                        ]"
                  aria-hidden="true"/>
                {{ t(item.name) }}
              </a>
              <a
                v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT' || item.name === 'Dexnet' || item.name === 'Fixglobaltrading'"
                :href="item.href"
                :target="item.name !== 'Dexnet' ? '_blank' : null"
                class="w-full flex px-2 py-2 items-center"
              >
                <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
                <component
                  v-else-if="item.name === 'Profix-Academy'"
                  :is="item.icon"
                  :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                  :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                />
                <component
                  v-else-if="item.name === 'Dexnet'"
                  :is="iconDexnet"
                  :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-21']"
                  :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-21']"
                />
                <span v-if="item.name === 'Dexnet'"></span>
                <span v-else-if="item.name === 'Fixglobaltrading'" ><img class="mr-3 flex-shrink-0 h-8 w-30" :src="iconFixglobaltrading"  alt=""></span>
                <span v-else>{{ item.name }}</span>
              </a>
            </template>
            
          </div>
          <hr class="flex h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700 w-full">
          <template v-if="isAdmin">
            <span class="text-center text-sm font-light text-gray-500 my-2">ADMIN PANEL</span>
            <div
              v-for="item in getAdminNavigation"
              :key="item.name"
              :class="[
              $route.path === '/' + item.href ? 'bg-orange-500 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
              'group flex items-center  text-sm font-medium rounded-md',
              ]">
              <router-link
                :to=" '/' + item.href"
                class="w-full flex px-2 py-2 items-center"
                v-if="item.name !== 'cryptohouse' && item.name !== 'xstarter'"
                v-show="!item.children || children"
              >
                <component
                  :is="item.icon"
                  :class="[
                item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6'
                ]"
                  aria-hidden="true"/>
                {{ t(item.name) }}
              </router-link>
            </div>
          </template>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import DexnetIconBlack from './icons/DexnetIconBlack.vue'
import DexnetIconWhite from './icons/DexnetIconWhite.vue'
import FixglobaltradingWhite from './icons/FixglobaltradingWhite.vue'
import FixglobaltradingBlack from './icons/FixglobaltradingBlack.vue'

const route = useRoute()
const { navigation, getAdminNavigation } = useStore().getters
const { t } = useI18n()
const children = ref(false)
const store = useStore()
const positiveBalance = computed(() => store.getters.getFixOneBalance || store.getters.getFixGoldBalance)
const sidebarNavigation = computed(() => {
  return store.getters.accessNavigation
})
const logo = computed(() => store.state.theme === 'dark' ? require('../assets/dark/logo-dark.png') : require('../assets/light/logo-light.png'))
const iconDexnet = computed(() => store.state.theme === 'dark' ? DexnetIconWhite : DexnetIconBlack)
const iconFixglobaltrading = computed(() => {
  return store.state.theme === 'dark' ? require(`@/assets/fixoneglobaltradingWthite.png`) : require(`@/assets/fixoneglobaltradingBlack.png`)
})

const checkout2Lvl = () => {
  document.querySelectorAll('.checkoutClass').forEach((item) => item.classList.toggle('hidden'))
  children.value = !children.value
}
const isAdmin = computed(() => store.getters.getUserInfo.is_admin)

const navItemSelected = e => {
  const navigationItem = e.target.closest('.navigation__unit')
  if (!navigationItem) return 
  const nestedItem = navigationItem.closest('.navigation__item').querySelector('.navigation__links_nested')
  if (!nestedItem) return
  nestedItem.classList.toggle('active')
}

const isCurrentLinkNested = computed(() => (
  item => {
    let hasCurrentLink = false
    item.innerLinks.forEach(link => {
      if (route.path === '/' + link.href) hasCurrentLink = true
    })
    return hasCurrentLink
  }
))

</script>


<style lang="scss">

.navigation__links_nested{
  display: none;

  &.active{
    display: flex;
    align-items: flex-start;
  }

  a{
    display: block;
    width: 100%;
    padding-left: 50px;
  }
}

.navigation__item--nested{

  &:hover{
    background: transparent !important;

    // .items-center{
    //   cursor: pointer;
    //   background: #f34128;
    // }
  }
}


.bg-main-dex{
  color: #000 !important;
}

.dex-link{
  &:hover{
    color: #000 !important;
  }
}

</style>