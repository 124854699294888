<template>
  <div class="flex items-center gap-1">
    <DarkModeIcon />
      <input type="checkbox" name="switch" id="switch" v-model="toggle">
      <label for="switch" class="border border-main-textSky dark:border-white"></label>
    <LoightModeIcon />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import DarkModeIcon from '../icons/DarkModeIcon.vue'
import LoightModeIcon from '../icons/LightModeIcon.vue'

// const state = ref(false)
const store = useStore()
const toggle = computed({
  get() {
    let state
    if (localStorage.theme === 'light') state = true
    else state = false

    return state
  },
  set(state) {
    if (state) {
      localStorage.theme = 'light'
    } else {
      localStorage.theme = 'dark'
    }
    store.commit('changeTheme')
  }
})
</script>

<style scoped>
#switch{
  font-size: 0;
}

input[type="checkbox"] {
  width: 0;
  height: 0;
  visibility: hidden;
}
label {
  width: 33px;
  height: 15px;
  display:block;
  background-color: inherit;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}
label::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #ff5a00;
  position: absolute;
  border-radius: 100px;
  top: 0.5px;
  left: 0.5px;
  transition: 0.5s;
}

input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

label:active:after {
  width: 13px;
}
</style>
