<template>
    <label :for="'copy' + id" class="relative flex flex-col sm:flex-row items-center gap-2 bg-green-500 text-white rounded-md p-2 hover:cursor-pointer whitespace-nowrap" @click="copy(id)">
      <input :id="'copy' + id" class="relative bg-green-500 text-center w-full grow focus:outline-none text-ellipsis pr-8 hover:cursor-pointer" :value="value" readonly/>
      <DocumentDuplicateIcon class="w-5 h-5 text-white absolute top-2 right-1" v-if="!isCopied" />
      <CheckIcon class="w-5 h-5 text-white absolute top-2 right-1" v-if="isCopied" />
    </label>
</template>

<script setup>
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/vue/outline'
import { ref } from 'vue'

const props = defineProps({
  id: Number,
  value: String,
})
const isCopied = ref(false)
const copy = (id) => {
  const referralCopy = document.querySelector(`#copy${id}`)
  referralCopy.select()
  document.execCommand('copy')
  isCopied.value = true
}
</script>
