export default {
  en: {
    verificationProgress: `<h4 class="text-2xl font-bold mb-2">Verification takes place within 24 hours, you will receive a notification by mail.</h4>
    <p class="mb-4">Wait for confirmation...</p>`,
    successfulVerification: `<h4 class="text-2xl font-bold mb-2">Congratulations, you are now part of Profixone Capital !</h4>
    <p class="mb-4">Together with you, we are creating this fund. Thank you and Welcome to your Personal Office</p>`,
    successfullDeletingTransaction: 'Your transaction has been successfully deleted',
    secureData: 'Personal data cannot be changed for security reasons. ',
    changeSecureData: 'If you want to change some of your personal data, please ',
    contactSupport: ' contact customer support.',
    passwordChanged: 'password changed!',
    avatarDeleted: 'Avatar has been deleted',
    addWalletsWarning: `We ask you to attach your personal FIX wallet in order to receive tokens on the blockchain. <br>
    This action is mandatory for investors who have more than 2500 FIX tokens in their account. <br>
    Go to the Wallets section. <br>
    If you have any questions, please contact the Support Service. <br>
    Sincerely, ProfiXone Capital team. <br>`,
    toSettings: 'go to settings',
    important: 'Important!',
  },
  ru: {
    verificationProgress: `<h4 class="text-2xl font-bold mb-2">Верификация происходит в течение 24 часов, вы получите уведомление по почте.</h4>
    <p class="mb-4">Дождитесь подтверждения…</p>`,
    successfulVerification: `<h4 class="text-2xl font-bold mb-2">Поздравляем, теперь вы часть Profixone Capital !</h4>
    <p class="mb-4">Вместе с Вами мы создаём этот фонд. Спасибо и Добро Пожаловать в Ваш Персональный Офис</p>`,
    successfullDeletingTransaction: 'Ваша транзакция успешно удалена',
    secureData: 'Личные данные не могут быть изменены по соображениям безопасности. ',
    changeSecureData: 'Если вы хотите изменить некоторые из ваших личных данных, пожалуйста, ',
    contactSupport: ' обратитесь в службу поддержки.',
    passwordChanged: 'Пароль успешно изменен!',
    avatarDeleted: 'Аватар был удален',
    addWalletsWarning: `Просим Вас прикрепить свой личный FIX кошелёк, для того чтобы получить токены на блокчейне. <br>
    Данное действие является обязательным для инвесторов у кого более 2500 FIX токенов на счету. <br>
    Перейдите в раздел Кошельки. <br>
    По возникшим вопросам обращайтесь в Службу Поддержки. <br>
    С уважением, команда ProfiXone Capital. <br>`,
    toSettings: 'перейти в настройки',
    important: 'Важно!',
  },
  es: {
    successfulVerification: `<h4 class="text-2xl font-bold mb-2">Felicidades, ahora eres parte Profixone Capital !</h4>
    <p class="mb-4">Junto con Usted creamos este fondo. Gracias y Bienvenidos a Su Oficina Personal</p>`,
    verificationProgress: `<h4 class="text-2xl font-bold mb-2">A verificación se realiza dentro de las 24 horas, recibirá una notificación por correo.</h4>
    <p class="mb-4">Espera la confirmación…</p>`,
    successfullDeletingTransaction: 'Su transacción se ha eliminado con éxito',
    secureData: 'Los datos personales no pueden ser modificados por razones de seguridad.',
    changeSecureData: 'Si desea cambiar algunos de sus datos personales, póngase en contacto con el Servicio de ',
    contactSupport: 'atención al cliente.',
    passwordChanged: 'contraseña cambiada!',
    avatarDeleted: 'El avatar ha sido eliminado',
    addWalletsWarning: `Le pedimos que adjunte su monedero personal FIX para poder recibir tokens en la blockchain. <br>
    Esta acción es obligatoria para los inversores que tengan más de 2500 tokens FIX en su cuenta. <br>
    Vaya a la sección Monederos. <br>
    Si tiene alguna pregunta, póngase en contacto con el Servicio de Asistencia. <br>
    Atentamente, equipo de ProfiXone Capital. <br>`,
    toSettings: 'ir a configuración',
    important: 'Importante',
  },
}
