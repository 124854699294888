export default {
  en: {
    refferalPartnerExplanation: `<p class="mb-3">This link is intended for investors who want to participate in the fund's affiliate program and earn additional bonuses.</p>
    <p class="mb-3">All sections of the affiliate program will be available upon registration at this link.</p>
    <p class="text-orange-600 text-base">Any additional questions left?<br>
    Contact us in the Personal Manager section.
    </p>`,
    refferalInvestorExplanation: `<p class="mb-3">This link is intended for investors who
    do not want to participate in the fund's partner program, it is enough for them to receive a stable income.</p>
    <p class="mb-3">When registering via this link, the functionality of depositing and withdrawing funds will be available. </p>
    <p class="text-orange-600 text-base">Any additional questions left?<br>
    Contact us in the Personal Manager section.
    </p>`,
  },
  ru: {
    refferalPartnerExplanation: `<p class="mb-3">Данная ссылка предназначена для инвесторов, которые хотят участвовать в партнерской программе фонда и зарабатывать дополнительные бонусы.</p>
    <p class="mb-3">Все разделы по партнерской программе будут доступны при регистрации по данной ссылке. </p>
    <p class="text-orange-600 text-base">Остались  дополнительные вопросы?<br>
    Свяжитесь с нами в разделе Личный менеджер.
    </p>`,
    refferalInvestorExplanation: `<p class="mb-3">Данная ссылка предназначена для инвесторов, которые 
    не хотят участвовать в партнерской программе фонда, им достаточно получать стабильный доход.</p>
    <p class="mb-3">При регистрации по данной ссылке будет доступен функционал ввода и вывода денежных средств. </p>
    <p class="text-orange-600 text-base">Остались  дополнительные вопросы?<br>
    Свяжитесь с нами в разделе Личный менеджер.
    </p>`,
  },
  es: {
    refferalPartnerExplanation: `<p class="mb-3">Este enlace está destinado a los inversores que desean participar en el programa de afiliados del fondo y ganar bonos adicionales.</p>
    <p class="mb-3">Todas las secciones del programa de afiliados estarán disponibles al registrarse en este enlace. </p>
    <p class="text-orange-600 text-base">Tienes más preguntas?<br>
    Póngase en contacto con nosotros en la sección Gerente de Personal.
    </p>`,
    refferalInvestorExplanation: `<p class="mb-3">Este enlace está dirigido a inversores que 
    no quieren participar en el programa de afiliados del fondo, son suficientes para recibir un ingreso estable.</p>
    <p class="mb-3">Al registrarse en este enlace, estará disponible la funcionalidad de entrada y salida de fondos. </p>
    <p class="text-orange-600 text-base">Tienes más preguntas?<br>
    Póngase en contacto con nosotros en la sección Gerente de Personal.
    </p>`,
  },
}
