export default {
  en: {
    growth: 'Growth',
    time: 'Time'
  },
  ru: {
    growth: 'Рост',
    time: 'Время'
  },
  es: {
    growth: 'Crecimiento',
    time: 'Tiempo'
  }
}
