<template>
  <div class="w-full px-4 sm:px-6 md:px-8">
    <div class="text-main">
      <h4 class="text-lg">{{ t('submitWithdraws') }}</h4>
      <p>{{ t('submitInstruction') }}</p>
      <my-input :title="t('hashTransaction')" class="mt-4" v-model="hash"/>
      <label for="uploadScreenshot" class="flex justify-between gap-1 border rounded-md cursor-pointer mt-4">
        <span class="p-1 truncate">{{ screenshot }}</span>
        <span class="border-l p-1 bg-gray-800 rounded-md">{{ t('screenshotTransaction') }}</span>
        <input type="file" id="uploadScreenshot" name="uploadScreenshot" class="hidden" @change="update">
      </label>
      <button class="btn px-6 mt-4" @click="confirmPayment">{{ t('submit') }}</button>
    </div>
  </div>
</template>

<script setup>
import MyInput from '@/components/UI/myInput.vue'
import { ref } from 'vue'
import axios from 'axios'
import { url } from '@/main'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const hash = ref('')
const screenshot = ref('')
const { t } = useI18n()
const emit = defineEmits(['updTransaction'])
const route = useRoute()
const update = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  screenshot.value = idScreenshot.name
}
const confirmPayment = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  const formData = new FormData()

  if (hash.value) formData.append('tx_id', hash.value)

  if (screenshot.value) formData.append('photo', idScreenshot)

  axios.post(`${url}/api/payments/withdraw/${route.params.id}`, formData, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then(() => {
      emit('updTransaction')
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('tokenAuth')
        document.location.href = document.location.origin
      }
    })
}
</script>
