<template>
  <Listbox v-model="selectedItem" @update:modelValue="$emit('update:modelValue', selectedItem)">
    <div class="relative">
      <label class="relative block text-sm">{{ title }}</label>
      <ListboxButton
        :class="[
          'relative w-full py-2 pl-3 pr-10 border border-[#a6b2bb] dark:border-[#292929] text-left bg-white dark:bg-[#171717] rounded-md shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm',
          styleBtn
          ]"
      >
        <span class="block truncate text-main-textSky dark:text-white">{{ t(selectedItem.name) }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
            <SelectorIcon class="w-5 h-5 text-main-textSky dark:text-white" aria-hidden="true" v-if="!hiddenIcon" />
          </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-[#171717] rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="person in props.state"
            :key="person.name"
            :value="person"
            as="template"
          >
            <li
              :class="[
                  active ? '!text-white bg-main-red' : '',
                  'cursor-default select-none relative py-2 pl-10 pr-4 text-main-textSky dark:text-white group',
                  hiddenIcon ? '!p-2' : ''
                ]"
            >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                    hiddenIcon ? 'text-center' : ''
                  ]"
                >{{ t(person.name) }}</span
                >
              <span
                v-if="selected && !hiddenIcon"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-main-textSky dark:text-white group-hover:text-white"
              >
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  state: Array,
  modelValue: Object,
  title: String,
  styleBtn: String,
  hiddenIcon: Boolean
})
const selectedItem = ref(props.state[0])
const { t } = useI18n()
</script>

<style>

</style>
