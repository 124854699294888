<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1224.06 230.5">
    <g id="Слой_2" data-name="Слой 2">
      <g id="White">
        <g id="_1" data-name="1">
          <path
            class="cls-1"
            d="M22.59,98.83,0,134.7l57.38.2L80.09,98.83,17.85,0Z"
          />
          <path
            class="cls-2"
            d="M27.61,197.67H142.34l62.25-98.84L142.34,0H42.75L71.5,45.65h42.09l33.5,53.18L113.59,152H.13Z"
          />
        </g>
        <g id="_2" data-name="2">
          <path
            class="cls-3"
            d="M286.42,175.38V23.55h69q24.74,0,43.6,9.43a71.81,71.81,0,0,1,29.5,26.46q10.62,17,10.63,40t-10.63,40A71.81,71.81,0,0,1,399,165.94q-18.87,9.44-43.6,9.44Zm35.35-28.63h31.89q15.18,0,26.35-5.75a41.49,41.49,0,0,0,17.35-16.48q6.18-10.74,6.18-25.06,0-14.52-6.18-25.16A41.67,41.67,0,0,0,380,57.93q-11.17-5.74-26.35-5.75H321.77Z"
          />
          <path
            class="cls-3"
            d="M500.5,147.18V112H571V84.5H500.5l-34.92.2v90.68H583.14v-28.2Z"
          />
          <path class="cls-3" d="M500.5,51.74h79.82V23.55H465.58V51.74Z" />
          <polygon
            class="cls-4"
            points="647.85 116.51 667.72 143.43 645.99 172.94 644.2 175.38 604.5 175.38 611.53 165.83 647.85 116.51"
          />
          <polygon
            class="cls-3"
            points="758.64 175.38 718.95 175.38 717.16 172.94 696.87 145.39 681.59 124.64 681.57 124.67 681.55 124.64 661.68 97.73 661.73 97.67 615.15 34.4 608.12 24.86 647.8 24.86 649.6 27.29 681.58 70.72 713.55 27.29 715.35 24.86 755.03 24.86 748 34.41 701.42 97.67 751.61 165.83 758.64 175.38"
          />
          <path
            class="cls-3"
            d="M788.08,175.38V23.55h29.07l89.58,109.32h-14.1V23.55h34.7V175.38H898.27L808.69,66.06h14.1V175.38Z"
          />
          <path class="cls-3" d="M998.26,51.74h79.82V23.55H963.34V51.74Z" />
          <path
            class="cls-3"
            d="M998.26,147.18V112h70.49V84.5H998.26l-34.92.2v90.68H1080.9v-28.2Z"
          />
          <path
            class="cls-3"
            d="M1140.33,175.38V52.18h-48.58V23.55h132.31V52.18h-48.59v123.2Z"
          />
          <path
            class="cls-3"
            d="M738.1,206.06v5.19h-22v-5.19ZM724.33,230.5V214.87h5.19V230.5Z"
          />
          <path
            class="cls-3"
            d="M783.81,206.06v5.19H762v-5.19Zm-16.65,19.25h16.65v5.19H762V214.84l5.19,0h14.47v5.19H767.16Z"
          />
          <path
            class="cls-3"
            d="M831.19,220.76l-.41,1.27a12.27,12.27,0,0,1-23.8-2,12,12,0,0,1-.13-1.8A12.26,12.26,0,0,1,819.11,206a12.16,12.16,0,0,1,7.43,2.51,12.32,12.32,0,0,1,4.35,6.35l.36,1.24h-5.38l-.25-.59a7.07,7.07,0,0,0-13.44,1.36,7.3,7.3,0,0,0-.14,1.4,7,7,0,0,0,.15,1.45,7.07,7.07,0,0,0,13.29,1.61l.27-.54Z"
          />
          <path
            class="cls-3"
            d="M876.77,206.1v24.4h-5.2V220.1H860.32v10.4h-5.19V206.1h5.19v8.81h11.25V206.1Z"
          />
          <path
            class="cls-3"
            d="M920.49,206V230.5h-1.24l-3.67-3.66-6.15-6.16-3.56-3.55-5.19-5.19v-6.06H902l13.33,13.33V206Zm-19.81,11.05,5.19,5.19v8.22h-5.19Z"
          />
          <path
            class="cls-3"
            d="M968.29,218.32a12.18,12.18,0,1,1-12.18-12.18A12.19,12.19,0,0,1,968.29,218.32Zm-12.18-7.1a7.1,7.1,0,1,0,7.1,7.1A7.11,7.11,0,0,0,956.11,211.22Z"
          />
          <path
            class="cls-3"
            d="M1011.63,225.31v5.19H992.18V205.88h5.19v19.43Z"
          />
          <path
            class="cls-3"
            d="M1056.61,218.32a12.18,12.18,0,1,1-12.18-12.18A12.19,12.19,0,0,1,1056.61,218.32Zm-12.18-7.1a7.1,7.1,0,1,0,7.1,7.1A7.11,7.11,0,0,0,1044.43,211.22Z"
          />
          <path
            class="cls-3"
            d="M1102.44,214.91v5.2h0V230.5h-10.68A12.54,12.54,0,0,1,1083,227a12,12,0,0,1,0-17.36,12.54,12.54,0,0,1,8.77-3.52h10.64v5.2h-11.72a7,7,0,0,0,0,14h6.57v-5.2h-6.06l-.26-.57-1.5-3.24-.64-1.39Z"
          />
          <path class="cls-3" d="M1132.31,206V230.5h-5.19V206Z" />
          <path
            class="cls-3"
            d="M1178,206.06v5.19h-21.85v-5.19Zm-16.66,19.25H1178v5.19h-21.85V214.84l5.19,0h14.48v5.19h-14.48Z"
          />
          <path
            class="cls-3"
            d="M1221.1,219.41a7.41,7.41,0,0,1-6.44,11.09h-7.35a7.09,7.09,0,0,1-6.83-5.19,7.31,7.31,0,0,1-.25-1.89h5.08a2,2,0,0,0,1.35,1.89h8a2.22,2.22,0,1,0,0-4.44h-7a7.46,7.46,0,0,1-5.43-2.36,7.7,7.7,0,0,1-1-1.37,7.41,7.41,0,0,1,6.44-11.09h7.48a7.07,7.07,0,0,1,6.82,5.19,6.9,6.9,0,0,1,.26,1.89h-5.09a2,2,0,0,0-1.34-1.89h-8.13a2.22,2.22,0,0,0,0,4.44h7A7.45,7.45,0,0,1,1221.1,219.41Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1,
.cls-4 {
  fill: #00fff0;
}
.cls-1,
.cls-2 {
  fill-rule: evenodd;
}
.cls-2,
.cls-3 {
  fill: #fff;
}
</style>
