export default {
  state: {
    languages: [
      { img: require('../../assets/country/lang-gb.png'), country: 'en', name: 'ENG' },
      { img: require('../../assets/country/spain.svg'), country: 'es', name: 'ESP' },
      { img: require('../../assets/country/russia.svg'), country: 'ru', name: 'РУС' }
    ],
    activeLanguage: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
  },
  getters: {
    languages(state) {
      return state.languages
    },
    activeLanguage(state) {
      return state.languages.find((item) => item.country === state.activeLanguage)
    }
  },
  mutations: {
    change(state, payload) {
      if (state.activeLanguage !== payload) {
        state.activeLanguage = payload
        localStorage.setItem('lang', payload)
      }
    }
  },
  actions: {
    CHANGE_LANGUAGE(context, payload) {
      context.commit('change', payload)
    }
  },
}
