/* eslint-disable */
import store from '@/store'

export function isNotFilledData() {
  if (store.state.userInfo.data_filled_at === null) return { path: '/data_verify' }
}
export function isNotVerifiedMail() {
  if (store.state.userInfo.email_verified_at === null) return { path: '/email_verify' }
}
export function isVerifiedMail() {
  if (store.state.userInfo.email_verified_at) return { path: '/' }
}
export function isAdmin() {
  if (store.getters.getUserInfo.is_admin !== 1) return { path: '/' }
}
export function isNotConfirmData() {
  if (store.getters.getUserInfo.pending_docs_verification_requests_count === 0 && store.getters.getUserInfo.verified_at === null) return { path: '/confirm_documents' }
}
export function isNotVerifiedData() {
  if (store.getters.getUserInfo.pending_docs_verification_requests_count === 1 && store.getters.getUserInfo.verified_at === null) return { path: '/verify_in_progress' }
}
