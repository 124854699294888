import LoadScript from 'vue-plugin-load-script'
import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import Auth from './views/AuthorizationPage.vue'
import './registerServiceWorker'
import router from './router'
import authRouter from './router/authRouter'
import store from './store'
import i18n from './i18n'
import './assets/tailwind.css'
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

export const url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : process.env.VUE_APP_API_URL
axios.defaults.headers.common['Access-Control-Allow-Origin'] = document.location.origin

if (localStorage.getItem('tokenAuth')) {
  axios.get(`${url}/api/user`, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
      Accept: 'application/json'
    }
  }).then((response) => {
    store.state.userInfo = response.data
    createApp(App).use(store).use(router).use(i18n)
      .use(LoadScript)
      .mount('#app')
  }).catch(() => {
    localStorage.removeItem('tokenAuth')
    createApp(App).use(store).use(router).use(i18n)
      .mount('#app')
  })
} else {
  createApp(Auth).use(store).use(authRouter).use(i18n)
    .mount('#auth')
}
