import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
    transactionsTypeTokens: {
      fixOneTransactions: [0, 1, 16, 17, 15, 7, 9],
      fixGoldTransactions: [11, 13, 14],
      fixTwoTransactions: [28, 29]
    }
  },
  getters: {
    getTransactionsTypeTokens(state) {
      return state.transactionsTypeTokens
    }
  },
  actions: {
    fetchTransactions(_, params) {
      return new Promise((res, rej) => {
        axios.get(`${url}/api/payments/transaction?page=${page}&type[]=0&count=20`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
          .then((response) => {
            res(response.data.data)
          })
          .catch((err) => {
            rej(err)
            if (err.response.status === 401) {
              localStorage.removeItem('tokenAuth')
              document.location.href = document.location.origin
            }
          })
      })
    }
  }
}
