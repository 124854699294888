import { createI18n } from 'vue-i18n'
import sidebar from './sidebar'
import main from './main'
import usermenu from './usermenu'
import myOfficePage from './myOfficePage'
import DepositPage from './DepositPage'
import TokenAccount from './TokenAccount'
import partnershipAccountPage from './partnershipAccountPage'
import globalPool from './globalPool'
import faqPage from './faqPage'
import auth from './auth'
import settings from './settings'
import towFactorPage from './towFactorPage'
import Transactions from './Transactions'
import notifications from './notifications'
import chart from './chart'
import marketing from './marketing'
import globalTour from './globalTour'
import dexnet from './dexnet'
import broadcast from './broadcast'
import transaction from './ui/transaction'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: {
      ...transaction.en,
      ...dexnet.en,
      ...broadcast.en,
      ...globalTour.en,
      ...marketing.en,
      ...chart.en,
      ...sidebar.en,
      ...main.en,
      ...usermenu.en,
      ...myOfficePage.en,
      ...DepositPage.en,
      ...TokenAccount.en,
      ...partnershipAccountPage.en,
      ...globalPool.en,
      ...faqPage.en,
      ...auth.en,
      ...settings.en,
      ...towFactorPage.en,
      ...Transactions.en,
      ...notifications.en,
    },
    ru: {
      ...transaction.ru,
      ...dexnet.ru,
      ...broadcast.ru,
      ...globalTour.ru,
      ...marketing.ru,
      ...chart.ru,
      ...sidebar.ru,
      ...main.ru,
      ...usermenu.ru,
      ...myOfficePage.ru,
      ...DepositPage.ru,
      ...TokenAccount.ru,
      ...partnershipAccountPage.ru,
      ...globalPool.ru,
      ...faqPage.ru,
      ...auth.ru,
      ...settings.ru,
      ...towFactorPage.ru,
      ...Transactions.ru,
      ...notifications.ru,
    },
    es: {
      ...transaction.es,
      ...dexnet.es,
      ...broadcast.es,
      ...globalTour.es,
      ...marketing.es,
      ...chart.es,
      ...sidebar.es,
      ...main.es,
      ...usermenu.es,
      ...myOfficePage.es,
      ...DepositPage.es,
      ...TokenAccount.es,
      ...partnershipAccountPage.es,
      ...globalPool.es,
      ...faqPage.es,
      ...auth.es,
      ...settings.es,
      ...towFactorPage.es,
      ...Transactions.es,
      ...notifications.es,
    }
  }, // set locale messages
})

export default i18n
