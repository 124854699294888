export default {
  en: {
    liveBroadcast: "ProfiXone Capital traders' live broadcast.",
    tradersResult: "The results of the foundation's work for the current month, year",
    terminalBrent: "Trading terminal. The fund's work with Brent crude oil.",
    terminalEth: "Trading terminal. The fund's work with the cryptocurrency Ethereum."
  },
  ru: {
    liveBroadcast: "Работа трейдеров ProfiXone Capital в прямом эфире.",
    tradersResult: "Результат работы фонда за текущий месяц, год.",
    terminalBrent: "Торговый терминал. Работа фонда с нефтью марки Brent.",
    terminalEth: "Торговый терминал. Работа фонда с криптовалютой Эфириум."
  },
  es: {
    liveBroadcast: "Transmisión en vivo de los traders de ProfiXone Capital.",
    tradersResult: "Resultados del trabajo del fondo para el mes y el año en curso.",
    terminalBrent: "Terminal de trading. Trabajo del fondo con petróleo crudo Brent.",
    terminalEth: "Terminal de trading. Trabajo del fondo con la criptomoneda Ethereum."
  }
}
