import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
    userInfo: {},
    stats: {},
    dexnet: {},
    twofa: false,
    stubDexnet: {
      wallet: '',
      "account": {
        "nodes_count": 0,
        "balance_dexnet": "0",
        "balance_dexnet_hold": "0",
        "balance_dexnet_total": "0"
      },
      "countries": {
          "sold_count": 1393,
          "activated_count": {}
      },
      "price": 0
    }
  },
  getters: {
    getUserProfixStatus(state) {
      return new Date(state.userInfo.created_at) < new Date(2024, 8, 9) ? 'fixone' : 'fixtwo'
    },
    getDexnetInfo(state) {
      return state.dexnet.dexnet ?? state.stubDexnet
    },
    getDexsafeWallet(state, getters) {
      return state.dexnet.wallet
    },
    getNodesBalance(state, getters) {
      return state.dexnet.nodes > getters.getDexnetInfo.account.nodes_count ? 
      state.dexnet.nodes : getters.getDexnetInfo.account.nodes_count 
    },
    getDexnetTokensPartnerBalance(state) {
      return state.dexnet.dexnet_balance
    },
    getDexnetTotalBalance(state, getters) {
      return getters.getDexnetInfo.account.balance_dexnet_total ?
        parseFloat(getters.getDexnetInfo.account.balance_dexnet_total) : 0
    },
    getDexCountries(state, getters) {
      const countries = getters.getDexnetInfo.countries.activated_count
      return Object.keys(countries).length
    },
    getDexSoldCount(state, getters) {
      return getters.getDexnetInfo.countries.sold_count
    },
    getDexTokenPrice(state, getters) {
      return getters.getDexnetInfo.price
    },
    getDexTokenChange(state, getters) {
      return getters.getDexnetInfo.change ?? 0
    },
    getDexTurnouver(state) {
      return state.dexnet.turnover ?? 0
    },
    getDexTurnoverCount(state) {
      return state.dexnet.turnover_count ?? 0
    },
    getDexValidatorDirectUSDT(state) {
      return state.dexnet.validator_direct_usdt ?? 0
    },
    getDexValidatorDirectDexnet(state) {
      return state.dexnet.validator_direct_dexnet ?? 0
    },
    getDexPotentialLos(state) {
      return state.dexnet.potential_loss ?? 0
    },
    getDexTeamBonus(state) {
      return state.dexnet.team_bonus ?? {}
    },
    getDexActiveStatus(state) {
      return state.dexnet.dexnet_avtive
    },
    getDexnetBalance(state) {
      return state.dexnet.balance ?? 0
    },
    getIsActiveStatus(state) {
      return state.stats.profixone_access 
    },
    getFixWalletExist(state) {
      return Boolean(state.userInfo.fix_wallet)
    },
    get2faStatus(state) {
      return Boolean(state.twofa)
    },
    getUserInfo(state) {
      return state.userInfo
    },
    getUserName(state) {
      return state.userInfo.first_name ? `${state.userInfo.first_name} ${state.userInfo.last_name}` : 'User Name'
    },
    getRole(state) {
      return state.userInfo.is_admin ? 'admin' : 'investor'
    },
    getAvatar(state) {
      return url + state.userInfo.photo
    },
    getFixOneWalletBalance(state) {
      return state.userInfo.fix_balance + state.userInfo.fix_bonus_balance
    },
    getFixOneBalance(state) {
      return state.userInfo.fix_balance + state.userInfo.fix_internal_balance + state.userInfo.fix_bonus_balance ?? 0
    },
    getFixOneCache(state) {
      return state.userInfo.personal_investment_cache ?? 0
    },
    getFixTwoBalance(state) {
      return state.userInfo.fix_two_balance ?? 0
    },
    getFixInternalBalance(state) {
      return state.userInfo.fix_internal_balance ?? 0
    },
    getFixGoldBalance(state) {
      return state.userInfo.fix_gold_balance + state.userInfo.fix_gold_internal_balance + state.userInfo.fix_gold_bonus_balance ?? 0
    },
    getStats(state) {
      return state.stats
    },
    getNodesAmount(state) {
      return state.dexnet.nodes ?? 0
    },
    getDexpool(state) {
      return state.dexnet.dexpool
    },
    getDexStats(state) {
      return state.dexnet
    }
  },
  mutations: {
    addUserInfo(state, data) {
      state.userInfo = data
      state.twofa = data.two_factor_confirmed
    },
    addStats(state, data) {
      state.stats = data
    },
    ADD_DEXNET_STATS(state, data) {
      state.dexnet = data
    }
  },
  actions: {
    logout() {
      axios.post(`${url}/logout`, {}, { withCredentials: true }).then(() => {
        document.location.href = document.location.origin
      })
    },
    createApiToken() {
      axios.post(`${url}/api/tokens/create`, {}, { withCredentials: true }).then(() => {
        document.location.href = document.location.origin
      })
    },
    clearCookie() {
      const deleteAllCookies = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf('=');
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
      }
      localStorage.removeItem('tokenAuth')

      const setCookie = (name, value, options = {}) => {
        /* eslint-disable */
        options = {
          path: '/',
          // при необходимости добавьте другие значения по умолчанию
          ...options
        }

        if (options.expires instanceof Date) {
          options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }

        document.cookie = updatedCookie;
      }

      
      setCookie('laravel_session', "", {
        'max-age': -1
      })
      setCookie('X-XSRF-TOKEN', "", {
        'max-age': -1
      })
      setCookie('XSRF-TOKEN', "", {
        'max-age': -1
      })

      // deleteAllCookies()

      deleteAllCookies()

    },
    fetchUserInfo({ commit }) {
      axios.get(`${url}/api/user`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          Accept: 'application/json'
        }
      }).then((response) => {
        commit('addUserInfo', response.data)
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('tokenAuth')
          // document.location.href = document.location.origin
        }
      })
    },
    fetchStats({ commit }) {
      axios.get(`${url}/api/profile/info/stats`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          Accept: 'application/json'
        }
      }).then((response) => {
        commit('addStats', response.data.data)
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('tokenAuth')
          // document.location.href = document.location.origin
        }
      })
      axios.get(`${process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3333' : url}/api/profile/info/dexnet`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          Accept: 'application/json'
        }
      }).then((response) => {
        commit('ADD_DEXNET_STATS', response.data.data)
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('tokenAuth')
          // document.location.href = document.location.origin
        }
      })
    },
  }
}
