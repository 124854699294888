<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton class="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
        <div class="text-[#758ba4] dark:text-white flex items-end flex-col mx-2">
          <strong>{{ userName }}</strong>
          <span class="block text-xs">{{ role }}</span>
        </div>
        <img class="h-10 w-10 rounded-full" :src="avatar" alt="ava" />
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-backgroundBlack ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuItem v-slot="{}">
          <div class="flex sm:hidden justify-between my-2">
          <DarkLightToggle class="pl-4 flex sm:hidden"/>
          <the-language class="block sm:hidden" />
        </div>
        </MenuItem>
        <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
          <router-link :to="item.href"
                       @click="logout(item.name)"
             :class="
              [active ? 'bg-main-red !text-white' : '',
              'flex px-4 py-2 text-sm text-gray-400 w-full items-center group',
              ]">
            <component
              :is="item.icon"
              :class="[
                        item.current ? 'text-white': 'text-gray-400 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                      ]"
              aria-hidden="true" />
            {{ t(item.name) }}
          </router-link>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount } from 'vue'
import DarkLightToggle from '@/components/UI/DarkLightToggle.vue'
import TheLanguage from '@/components/TheLanguage.vue'

const { userNavigation } = useStore().getters
const { t } = useI18n()
const store = useStore()
const userName = computed(() => store.getters.getUserName)
const role = computed(() => store.getters.getRole)
const avatar = computed(() => store.getters.getAvatar)

const { getUserInfo } = store.getters
const logout = async (pathName) => {
  if (pathName === 'logout') {
    store.dispatch('logout').then(async () => {
      await store.dispatch('clearCookie')
    })
  }
}
</script>
